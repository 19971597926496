import React, { useEffect, useState } from 'react'
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalActions,
} from 'semantic-ui-react'
import classnames from 'classnames'

import { Button, ListRow } from '@labsavvyapp/ui-components'
import sharedStyle from '../../../../styles/shared.module.css'

// Styles
import style from '../DataManagementPage.module.css'

const ModalCompendiaList = ({
  show,
  provider,
  dirtyLaundry,
  uploadFileData,
  setState,
  importPricing,
}) => {
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    setShowModal(show)
  }, [show, uploadFileData])

  const rowList = (items, dirty = false) =>
    items.map((item, index) => (
      <ListRow
        key={index}
        className={
          dirty
            ? classnames(sharedStyle.listRow, style.row, style.rowRed)
            : classnames(sharedStyle.listRow, style.row)
        }
      >
        <div className={style.column} style={{ width: '10%' }}>
          {item.order_code}
        </div>
        <div className={style.column} style={{ width: '30%' }}>
          {item.order_name}
        </div>
        <div className={style.column} style={{ width: '15%' }}>
          {item.result_code}
        </div>
        <div className={style.column} style={{ width: '30%' }}>
          {item.result_name}
        </div>
        <div className={style.column} style={{ width: '15%' }}>
          {item.loinc_code}
        </div>
      </ListRow>
    ))

  return (
    <Modal
      size="fullscreen"
      open={showModal}
      onClose={() => setState({ modal: false })}
    >
      <ModalHeader>
        {(uploadFileData || dirtyLaundry) && (
          <div
            style={{
              display: 'flex',
              backgroundColor: '#ECECEC',
              fontSize: 13,
              padding: 10,
            }}
          >
            <div style={{ width: '10%' }}>
              <b>Order Code</b>
            </div>
            <div style={{ width: '30%' }}>
              <b>Order Name</b>
            </div>
            <div style={{ width: '15%' }}>
              <b>Result Code</b>
            </div>
            <div style={{ width: '30%' }}>
              <b>Result Name</b>
            </div>
            <div style={{ width: '15%' }}>
              <b>Loinc</b>
            </div>
          </div>
        )}
      </ModalHeader>
      <ModalContent scrolling style={{ padding: '10px 22px 10px 30px' }}>
        {dirtyLaundry && rowList(dirtyLaundry, true)}
        {uploadFileData && rowList(uploadFileData)}
      </ModalContent>
      <ModalActions style={{ paddingTop: 30 }}>
        {dirtyLaundry?.length > 0 ? (
          'Please check the compendium data before uploading the file.'
        ) : (
          <>
            This will attempt to import <b>{uploadFileData?.length}</b>{' '}
            compendia to <b style={{ paddingRight: 10 }}>{provider?.name}</b>
          </>
        )}{' '}
        <Button
          disabled={dirtyLaundry?.length > 0}
          onClick={() => {
            setState({ modal: false })
            importPricing()
          }}
        >
          Import Data
        </Button>
        <Button
          className={style.ml5}
          onClick={() => setState({ modal: false })}
          variant={Button.variant.secondary}
        >
          Cancel
        </Button>
      </ModalActions>
    </Modal>
  )
}

export default ModalCompendiaList
