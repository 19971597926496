import React, { useEffect, useState } from 'react'
import {
  Input,
  Modal,
  ModalHeader,
  ModalContent,
  ModalActions,
} from 'semantic-ui-react'
import classnames from 'classnames'

import { Button, Form, ListRow } from '@labsavvyapp/ui-components'
import sharedStyle from '../../../../styles/shared.module.css'

// Styles
import style from '../DataManagementPage.module.css'

const ModalMainPricingList = ({
  show,
  provider,
  dirtyLaundry,
  uploadFileData,
  setState,
  importPricing,
}) => {
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    setShowModal(show)
  }, [show, uploadFileData])

  const rowList = (items, dirty = false) =>
    items.map((item, index) => (
      <ListRow
        key={index}
        className={
          dirty
            ? classnames(sharedStyle.listRow, style.row, style.rowRed)
            : classnames(sharedStyle.listRow, style.row)
        }
      >
        <div className={style.firstColumn}>{item.test_code}</div>
        <div className={style.secondColumn}>{item.test_name}</div>

        <div className={style.thirdColumn}>
          <Form>
            <Input
              name="provider_price"
              icon="dollar"
              iconPosition="left"
              type="number"
              placeholder={0}
              value={item.provider_price}
              className={style.amount}
              // onChange={(e) => handleSaveMainPricing(item, e)}
            />
          </Form>
        </div>
        <div className={style.fourthColumn}>
          <Form>
            <Input
              name="ls_price"
              icon="dollar"
              iconPosition="left"
              type="number"
              placeholder={0}
              value={item.ls_price}
              className={style.amount}
              // onChange={(e) => handleSaveMainPricing(item, e)}
            />
          </Form>
        </div>
        <div className={style.fifthColumn}>
          <Form>
            <Input
              name="standard_retail_price"
              icon="dollar"
              iconPosition="left"
              type="number"
              placeholder={0}
              value={item.standard_retail_price}
              className={style.amount}
              // onChange={(e) => handleSaveMainPricing(item, e)}
            />
          </Form>
        </div>
      </ListRow>
    ))

  return (
    <Modal
      size="fullscreen"
      open={showModal}
      onClose={() => setState({ modal: false })}
    >
      <ModalHeader>
        {(uploadFileData || dirtyLaundry) && (
          <div
            style={{
              display: 'flex',
              backgroundColor: '#ECECEC',
              fontSize: 13,
              padding: 10,
            }}
          >
            <div style={{ width: '10%' }}>
              <b>Order Code</b>
            </div>
            <div style={{ width: '51%' }}>
              <b>Order Name</b>
            </div>
            <div style={{ width: '13%' }}>
              <b>LS Cost</b>
            </div>
            <div style={{ width: '13%' }}>
              <b>LS Standard Price</b>
            </div>
            <div style={{ width: '13%' }}>
              <b>Standard Retail Price</b>
            </div>
          </div>
        )}
      </ModalHeader>
      <ModalContent scrolling style={{ padding: '10px 22px 10px 30px' }}>
        {dirtyLaundry && rowList(dirtyLaundry, true)}
        {uploadFileData && rowList(uploadFileData)}
      </ModalContent>
      <ModalActions style={{ paddingTop: 30 }}>
        {dirtyLaundry?.length > 0 ? (
          'Please check the main pricing data before uploading the file.'
        ) : (
          <>
            This will attempt to import <b>{uploadFileData?.length}</b> main
            pricing data to <b style={{ paddingRight: 10 }}>{provider?.name}</b>
          </>
        )}{' '}
        <Button
          disabled={dirtyLaundry?.length > 0}
          onClick={() => {
            setState({ modal: false })
            importPricing()
          }}
        >
          Import Data
        </Button>
        <Button
          className={style.ml5}
          onClick={() => setState({ modal: false })}
          variant={Button.variant.secondary}
        >
          Cancel
        </Button>
      </ModalActions>
    </Modal>
  )
}

export default ModalMainPricingList
