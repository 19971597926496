import React, { useEffect, useState } from 'react'
import { Button } from '@labsavvyapp/ui-components'
import { Dropdown } from 'semantic-ui-react'
import _ from 'lodash'

export default function DataPartnerPricingRow({ data, setState }) {
  const [partner, setPartner] = useState('')
  const [provider, setProvider] = useState('')
  const [providers, partners] = data.data

  useEffect(() => {
    setPartner(partners[0])
  }, [partners])

  useEffect(() => {
    setProvider(providers[0])
  }, [providers])

  const handleChangePartner = async (event, result) => {
    const { value } = result || event.target
    setSelected(setPartner, value, partners)
  }

  const handleChangeProvider = async (event, result) => {
    const { value } = result || event.target
    setSelected(setProvider, value, providers)
  }

  const setSelected = (func, val, items) => {
    let selected = _.find(items, { _id: val })
    func(selected)
  }

  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        paddingTop: 10,
        paddingBottom: 10,
        borderBottom: '1px solid #ECECEB',
      }}
      key={data.index}
    >
      <div
        style={{
          width: '55%',
          flexDirection: 'column',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div style={{ fontWeight: 600, fontSize: 16 }}>{data.title} </div>
        <div style={{ fontWeight: 200, fontSize: 14 }}>{data.subtitle}</div>
      </div>

      <div style={{ width: '30%', textAlign: 'right', paddingRight: 30 }}>
        <div style={{ marginBottom: '5px' }}>
          <span style={{ fontSize: 12, marginRight: 10 }}>Laboratory :</span>
          {providers && (
            <Dropdown
              selection
              options={providers.map((item) => ({
                text: item.name,
                value: item._id,
                key: item._id,
              }))}
              onChange={handleChangeProvider}
              defaultValue={providers[0]?._id}
              search
            />
          )}
        </div>
        <div>
          <span style={{ fontSize: 12, marginRight: 10 }}>
            {data.dataName} :
          </span>
          {partners && (
            <Dropdown
              selection
              options={partners.map((item) => ({
                text: item.name,
                value: item._id,
                key: item._id,
              }))}
              onChange={handleChangePartner}
              defaultValue={partners[0]?._id}
              search
            />
          )}
        </div>
      </div>

      <div
        style={{
          width: '15%',
          textAlign: 'center',
          paddingRight: 30,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          onClick={() => {
            setState({
              pricing: 'partner',
              partner: partner,
              provider: provider,
            })
            data.action.current.click()
          }}
          size="small"
        >
          {data.actionButtonName}
        </Button>
      </div>
    </div>
  )
}
