import React, { useEffect, useState, useRef, useReducer } from 'react'
import { Message } from 'semantic-ui-react'
import { useSavingModals } from '@labsavvyapp/ui-components'
import { useQuery, useMutation } from 'react-apollo'

// Components
import DataCompendiaRow from './Compendium/DataCompendiaRow'
import DataManagementRow from './MainPricing/DataManagementRow'
import DataPartnerPricingRow from './PartnerPricing/DataPartnerPricingRow'
import ModalCompendiaList from './Compendium/ModalCompendiaList'
import ModalMainPricingList from './MainPricing/ModalMainPricingList'
import ModalPartnerPricingList from './PartnerPricing/ModalPartnerPricingList'

// Utilities
import { read, utils } from 'xlsx'

// Queries and Mutations
import { ListPartners } from '../../../graphql/partner/queries'
import { ListProviders } from '../../../graphql/providers/queries'
import {
  ImportCompendium,
  ImportMainPricing,
  ImportPartnerPricing,
} from '../../../graphql/settings/mutations'

export default function DataManagementPage() {
  const inputFile = useRef(null)

  // Menu Items with no data dependencies
  const [maintenanceItems, setMaintenanceItems] = useState([])

  // Key fields state handler
  const [state, setState] = useReducer((prev, next) => ({ ...prev, ...next }), {
    pricing: 'main', // [compendia, main pricing, partner pricing]
    partner: '',
    provider: '',
    modal: false,
    message: false,
  })

  // Query handlers
  const { data: { listProviders: providers } = {} } = useQuery(ListProviders, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })
  const { data: { listPartners: partners } = {} } = useQuery(ListPartners, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })

  // Mutation handlers
  const [importCompendium, { data: importCompendiumStatus }] =
    useMutation(ImportCompendium)
  const [importMainPricing, { data: importMainPricingStatus }] =
    useMutation(ImportMainPricing)
  const [importPartnerPricing, { data: importPartnerPricingStatus }] =
    useMutation(ImportPartnerPricing)

  // Modals
  const [saveCompendiumModal, { showModals: startImportCompendium }] =
    useSavingModals({
      savingMessage: `We are importing compendium for ${state.provider?.name}. Please wait...`,
      savedMessage: `${importCompendiumStatus?.importCompendium?.message}:
                      ${importCompendiumStatus?.importCompendium?.args[0]},
                      ${importCompendiumStatus?.importCompendium?.args[1]},
                      ${importCompendiumStatus?.importCompendium?.args[2]}`,
      callback: async () => {
        await importCompendium({
          variables: {
            providerId: state.provider?._id,
            input: uploadFileData,
          },
        })

        // match modal delay before showing the failed message
        setTimeout(() => setState({ message: true }), 3000)
      },
      onError: (error) => error,
    })

  const [saveMainPricingModal, { showModals: startImportMainPricing }] =
    useSavingModals({
      savingMessage: `We're importing main pricing data for ${state.provider?.name}. Please wait...`,
      savedMessage: importMainPricingStatus?.importMainPricing?.message,
      callback: async () => {
        await importMainPricing({
          variables: {
            providerId: state.provider?._id,
            input: uploadFileData,
          },
        })
      },
      onError: (error) => error,
    })

  const [savePartnerPricingModal, { showModals: startImportPartnerPricing }] =
    useSavingModals({
      savingMessage: `We're importing partner pricing data for ${state.partner?.name}. Please wait...`,
      savedMessage: importPartnerPricingStatus?.importPartnerPricing?.message,
      callback: async () => {
        await importPartnerPricing({
          variables: {
            partnerId: state.partner?._id,
            providerId: state.provider?._id,
            input: uploadFileData,
          },
        })
      },
      onError: (error) => error,
    })

  // Rerender if data dependecies are loaded
  useEffect(() => {
    if (providers && partners) {
      setMaintenanceItems(() => [
        {
          title: 'Import Compendium data from csv or xls file',
          subtitle:
            'Columns: order_code, order_name, result_code, result_name, loinc_code',
          action: inputFile,
          dataName: 'Laboratory',
          data: providers.providers,
          actionButtonName: 'Upload',
          type: 'compendia',
        },
        {
          title: 'Import Main Pricing data from csv or xls file',
          subtitle:
            'Columns: order_code, order_name, provider_price, ls_price, standard_retail_price',
          action: inputFile,
          dataName: 'Laboratory',
          data: providers.providers,
          actionButtonName: 'Upload',
          type: 'main',
        },
        {
          title: 'Import Partner Pricing data from csv or xls file',
          subtitle:
            'Columns: order_code, order_name, contract_price, retail_price',
          action: inputFile,
          dataName: 'Partner',
          data: [providers.providers, partners.partners],
          actionButtonName: 'Upload',
          type: 'partner',
        },
      ])
    }
  }, [providers, partners])

  const [uploadFileData, setUploadFileData] = useState()
  const [dirtyLaundry, setDirtyLaundry] = useState()

  const readUploadFile = (e) => {
    e.preventDefault()
    if (e.target.files) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const data = e.target.result
        const workbook = read(data, { type: 'array' })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const json = utils.sheet_to_json(worksheet)

        // allowed fields for each upload type;
        const allowedFields = {
          compendia: [
            'order_code',
            'order_name',
            'result_code',
            'result_name',
            'loinc_code',
          ],
          // main pricing
          main: [
            'order_code',
            'order_name',
            'provider_price',
            'ls_price',
            'standard_retail_price',
          ],
          // partner pricing
          partner: [
            'order_code',
            'order_name',
            'contract_price',
            'retail_price',
          ],
        }

        const prices = [
          'provider_price',
          'ls_price',
          'standard_retail_price',
          'contract_price',
          'retail_price',
        ]
        const invalid = ['', '#N/A', '0', 0, undefined, null]
        const clean = []
        const dirty = []

        json.forEach((row) => {
          // initialize keys for the upload type
          const rowData = {}
          allowedFields[state.pricing].forEach((obj) => {
            const rowObj =
              typeof row[obj] === 'string'
                ? row[obj].trim()
                : row[obj]?.toString().trim()

            if ('compendia' === state.pricing) {
              // allow empty if lionc_code
              if ('loinc_code' === obj) {
                rowData[obj] = invalid.includes(rowObj) ? '' : rowObj
              } else {
                // don't include invalid values
                if (!invalid.includes(rowObj)) {
                  rowData[obj] = rowObj
                }
              }
            }
            // main/partner pricing
            else {
              // required
              if ('order_code' === obj && !invalid.includes(rowObj)) {
                rowData['test_code'] = rowObj
              }
              // required
              if ('order_name' === obj && !invalid.includes(rowObj)) {
                rowData['test_name'] = rowObj
              }
              // parseFloat pricing inputs
              if (prices.includes(obj)) {
                rowData[obj] = rowObj ? parseFloat(rowObj) : 0
              }
            }
          })

          if (
            Object.keys(rowData).length === allowedFields[state.pricing].length
          ) {
            clean.push(rowData)
          } else {
            dirty.push(rowData)
          }
        })

        setDirtyLaundry(dirty)
        setUploadFileData(clean)
        setState({ modal: true })
      }
      reader.readAsArrayBuffer(e.target.files[0])
    }
    // reset input file
    inputFile.current.value = null
  }

  return (
    <div>
      {maintenanceItems?.map((data, index) => {
        switch (data.type) {
          case 'compendia':
            return (
              <>
                <DataCompendiaRow data={data} key={index} setState={setState} />
                {state.message &&
                  importCompendiumStatus?.importCompendium?.failed &&
                  importCompendiumStatus?.importCompendium?.failed.length >
                    0 && (
                    <div
                      style={{
                        margin: '0.3rem 2rem 2rem 0',
                      }}
                    >
                      <Message
                        icon="warning sign"
                        error
                        onDismiss={() => setState({ message: false })}
                        header={
                          importCompendiumStatus?.importCompendium?.message
                        }
                        list={importCompendiumStatus?.importCompendium?.failed}
                      />
                    </div>
                  )}
              </>
            )
          case 'main':
            return (
              <DataManagementRow data={data} key={index} setState={setState} />
            )
          case 'partner':
            return (
              <DataPartnerPricingRow
                data={data}
                key={index}
                setState={setState}
              />
            )
          default:
            break
        }
      })}

      <input
        type="file"
        id="file"
        ref={inputFile}
        onChange={readUploadFile}
        style={{ display: 'none' }}
        accept=".xlsx, .xls, .csv"
      />

      <ModalCompendiaList
        show={state.pricing === 'compendia' && state.modal}
        provider={state.provider}
        dirtyLaundry={dirtyLaundry}
        uploadFileData={uploadFileData}
        setState={setState}
        importPricing={startImportCompendium}
      />
      <ModalMainPricingList
        show={state.pricing === 'main' && state.modal}
        provider={state.provider}
        dirtyLaundry={dirtyLaundry}
        uploadFileData={uploadFileData}
        setState={setState}
        importPricing={startImportMainPricing}
      />
      <ModalPartnerPricingList
        show={state.pricing === 'partner' && state.modal}
        partner={state.partner}
        dirtyLaundry={dirtyLaundry}
        uploadFileData={uploadFileData}
        setState={setState}
        importPricing={startImportPartnerPricing}
      />

      {saveCompendiumModal}
      {saveMainPricingModal}
      {savePartnerPricingModal}
    </div>
  )
}
